import React from "react"
import PropTypes from "prop-types"
import classes from "./CareerCard.module.scss"
import Vector from "src/assets/icons/vector.svg"
import SvgIcon from "src/components/SvgIcon"
import Arrow from "src/assets/icons/arrow-next.svg"
import Dotes from "src/assets/icons/dotes.svg"
import TechnologyWrapper from "../layout/TechnologyWrapper/TechnologyWrapper"

const CareerCard = ({ technologies, title, workPlaces }) => {
  return (
    <div className={classes.careerCard}>
      <div className={classes.technologyWrapper}>
        {technologies.map((technology, index) => {
          if (index < 3) {
            return (
              <TechnologyWrapper title={technology.name} key={technology.id} />
            )
          }
          if (index === 3) {
            return (
              <span className={classes.svgWrapper}>
                <SvgIcon icon={Dotes} key={index + 0.01} />
              </span>
            )
          }
        })}
      </div>
      <div className={classes.title}> {title} </div>
      <div className={classes.description}></div>
      <div className={classes.footer}>
        <ul>
          {workPlaces.map(place => {
            return (
              <li key={place.id} className={classes.workPlace}>
                <SvgIcon icon={Vector} /> {place.place}
              </li>
            )
          })}
        </ul>
        <span className={classes.moreInfo}>
          More <SvgIcon icon={Arrow} />{" "}
        </span>
      </div>
    </div>
  )
}

CareerCard.propTypes = {
  technologies: PropTypes.array,
  title: PropTypes.string,
  workPlace: PropTypes.array,
}

export default CareerCard
